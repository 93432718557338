import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import FileUpload from "../../Components/FileUpload/FileUpload";
import { FaPlusCircle } from "react-icons/fa";
import { addArticle } from "../../../services/articleServices/articleServices";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import globalLoader from "../../../assets/images/loader.svg";
import allowedFileExtensions, {
  MyCustomUploadAdapterPlugin,
  base64ToFile,
  translate,
} from "../../../utility/helper";
import { useLanguage } from "../../Context/languageContext";
import {
  addProjects,
  languagesOptsList,
  projectList,
  uploadDocx,
} from "../../../services/ProjectServices/projectServices";
import PixabayImageSearch from "../../Components/Pixabay/pixabay";
import Select1 from "react-select";
import { baseURL2 } from "../../../utility/data";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsQuestionCircle } from "react-icons/bs";

const AddArticle = () => {
  const accessToken = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("lang");
  const initialValues = {
    document: "",
    project: "",
    title: "",
    lead: "",
    addImage: "",
  };

  const initialValues1 = {
    projectName: "",
    webAddress: "",
    publicationLang: "",
  };

  const [formValues1, setFormValues1] = useState(initialValues1);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [articlesData2, setArticlesData2] = useState([]);
  const [content, setContent] = useState("");
  const [displayedImage, setDisplayedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { languageData } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [buttonName, setButtonName] = useState(true);
  const [languagesOpts, setLanguagesOpts] = useState([]);
  const navigate = useNavigate();

  const handleSelectChange1 = (selectedOption) => {
    setFormValues1({ ...formValues1, publicationLang: selectedOption?.value });
    validateProjectModal({
      ...formValues1,
      publicationLang: selectedOption?.value,
    });
  };

  useEffect(() => {
    if (selectedFile) {
      uploadDocxServices();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (!showModal) {
      setFormValues1(initialValues1);
    }
  }, [showModal]);

  useEffect(() => {
    if (lang && languagesOpts.length > 0) {
      const dropdownbind = languagesOpts.find((item) => item.code === lang);
      if (dropdownbind) {
        setFormValues1((prev) => ({
          ...prev,
          publicationLang: dropdownbind.value,
        }));
      }
    }
  }, [languagesOpts, lang]);

  useEffect(() => {
    languagesOptsServices();
    articleListServices2();
  }, []);

  const resetIsData = () => {
    setButtonName(true);
  };

  // const handleChange1 = (e) => {
  //   setFormValues1({ ...formValues1, [name]: value });
  //   validateProjectModal({ ...formValues1, [name]: value });
  // };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    if (trimmedValue === "" || trimmedValue.startsWith(" ")) {
      setFormValues1({ ...formValues1, [name]: "" });
      validateProjectModal({ ...formValues1, [name]: "" });
    } else {
      setFormValues1({ ...formValues1, [name]: trimmedValue });
      validateProjectModal({ ...formValues1, [name]: trimmedValue });
    }
  };

  const languagesOptsServices = async () => {
    setLoading(true);
    try {
      const res = await languagesOptsList();
      const mappedOptions = res.languages.map((language) => ({
        value: language.englishName,
        label: language.englishName,
        flag: `${baseURL2}/LinkSellingSystem/public/${language.image}`,
        code: language.code
      }));
      setLanguagesOpts(mappedOptions);
    } catch (error) {
      console.error("Error fetching language options:", error);
    } finally {
      setLoading(false);
    }
  };

  const addProjectService = async () => {
    try {
      setLoading(true);
      const res = await addProjects(formValues1, accessToken);

      if (res.response === true && res.success === true) {
        toast(translate(languageData, "Projectaddedsucessfully"), {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });
        await articleListServices2();
        // setProject(res.data.id);
      } else if (res.success === false && res.response) {
        for (const field in res.response) {
          if (res.response.hasOwnProperty(field)) {
            const errorMessages = res.response[field].map((message) => {
              const translationKey = fieldTranslationMap[field] || field;
              return `${translate(languageData, translationKey)}`;
            });
            const errorMessage = errorMessages.join(". ");
            toast(errorMessage, {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "error",
            });
          }
        }
      } else {
        toast(translate(languageData, "loginFailureMessage2"), {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });
      }
    } catch (error) {
      toast(translate(languageData, "An unexpected error occurred"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
      console.error("Error in addProjectService:", error);
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  //pixabay Image selct start//

  const handlePixabayImageSelect = (selectedPixabayImage) => {
    fetch(selectedPixabayImage.largeImageURL)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const blob = new Blob([buffer], { type: "image/jpeg" });
        const file = new File([blob], "pixabay.jpeg", { type: "image/jpeg" });
        const previewUrl = URL.createObjectURL(file);
        setFormValues({
          ...formValues,
          addImage: file,
        });

        setDisplayedImage(previewUrl);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  //pixabay Image selct end//

  const allowedDocExtensions = [".docx"];
  // const allowedImageExtension = [".jpg", ".gif", ".png"];

  const handleFiles = (file) => {
    if (file instanceof Blob) {
      setFormValues({ ...formValues, addImage: file });

      const reader = new FileReader();
      reader.onloadend = () => {
        setDisplayedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file or no file selected.");
    }
  };

  const fieldTranslationMap = {
    content: translate(languageData, "ContentField"),
    document: translate(languageData, "DocumentField"),
    image: translate(languageData, "ImageField"),
    lead: translate(languageData, "LeadField"),
    project: translate(languageData, "ProjectNameField"),
    title: translate(languageData, "TitleField"),
  };

  const handleAddArticleServices = async (type) => {
    if (type === "saveandexit") {
      setLoading2(true);
    } else if (type === "save") {
      setLoading(true);
    }
    const res = await addArticle(formValues, content, accessToken);
    if (res.response === true && res.success === true) {
      toast(translate(languageData, "articleAddedSuccessfully"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        type: "success",
      });
      if (type === "saveandexit") {
        setLoading2(false);
        navigate("/articleList");
      } else {
        setLoading(false);
      }

      setFormValues(initialValues);
      setContent("");
      setDisplayedImage(null);
      setSelectedFile(null);
      setButtonName(false);
    } else if (res.success === false && res.response) {
      for (const field in res.response) {
        if (res.response.hasOwnProperty(field)) {
          const errorMessages = res.response[field].map((message) => {
            const translationKey = fieldTranslationMap[field] || field;
            return `${translate(languageData, translationKey)}`;
          });
          const errorMessage = errorMessages.join(". ");
          toast(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "error",
          });
        }
      }
    } else {
      toast(translate(languageData, "Somthing went wrong"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    }
    if (type === "saveandexit") {
      setLoading2(false);
    } else if (type === "save") {
      setLoading(false);
    }
  };

  const articleListServices2 = async () => {
    const res = await projectList(accessToken);
    setArticlesData2(res?.data.reverse());
  };

  const handleChange = (e) => {
    if (e.target && e.target.name) {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
      validate({ ...formValues, [name]: value });
    }
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
    validate({ ...formValues, image: file });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };

  const validateProjectModal = (values) => {
    let errors = {};
    let isValid = true;
    let urlRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;

    if (!values.projectName) {
      errors.projectName = translate(languageData, "ProjectNameRequired");
      isValid = false;
    }

    if (!values.webAddress) {
      errors.webAddress = translate(languageData, "WebAddressField");
      isValid = false;
    } else if (!urlRegex.test(values.webAddress)) {
      errors.webAddress = translate(languageData, "InvalidLink");
      isValid = false;
    }

    if (!values.publicationLang) {
      errors.publicationLang = translate(
        languageData,
        "PublicationLanguageRequired"
      );
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const validate = (values) => {
    let errors = {};
    let isValid = true;

    if (!values.project) {
      errors.project = translate(languageData, "PleaseSelectYourProject");
      isValid = false;
    }

    if (!values.title) {
      errors.title = translate(languageData, "TitleField");
      isValid = false;
    }

    if (!values.lead) {
      errors.lead = translate(languageData, "LeadField");
      isValid = false;
    }

    if (!values.content) {
      errors.content = translate(languageData, "ContentField");
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const uploadDocxServices = async () => {
    setLoading(true);
    const res = await uploadDocx(selectedFile, lang, accessToken);
    if (res.success === true) {
      toast(translate(languageData, "docxFileUploadSuccessfully"), {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
      });

      if (res?.title && res?.lead && res?.content) {
        setFormValues({
          ...formValues,
          title: res?.title.trim().replace(/\s+/g, " "),
          lead: res?.lead.trim().replace(/\s+/g, " "),
          content: res?.content,
          addImage: res?.images[0]
            ? base64ToFile(res?.images[0])
            : formValues?.addImage,
        });
      }
      setFormErrors("");
      setContent(res?.content);
      setDisplayedImage(res?.images[0] ? res?.images[0] : displayedImage);
    } else if (
      res.success === false &&
      res.message.file[0] === "The file must be a file of type: docx."
    ) {
      toast(`${translate(languageData, "pleaseUploadDocxFile")}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
      
      setFormValues({
        title: "",
        lead: "",
        content: "",
        addImage: "",
        project: "",
      });
      setContent("");
      setDisplayedImage("");
      setFormErrors({
        ...formErrors,
        document: translate(languageData, "pleaseUploadDocxFile"),
      });
    } else if (
      res.success === false &&
      res.message.file[0] === "The file type is not allowed. Files with \".pl.docx\" are not allowed."
    ) {
      toast(`${translate(languageData, "notAllowThisFile")}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
      
      setFormValues({
        title: "",
        lead: "",
        content: "",
        addImage: "",
        project: "",
      });
      setContent("");
      setDisplayedImage("");
      setFormErrors({
        ...formErrors,
        document: translate(languageData, "notAllowThisFile"),
      });
    } else if (
      res.success === false &&
      res.message.file[0] === "The file name contains multiple dots. Only one dot before the extension is allowed."
    ) {
      toast(`${translate(languageData, "notAllowMultipleDots")}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
      
      setFormValues({
        title: "",
        lead: "",
        content: "",
        addImage: "",
        project: "",
      });
      setContent("");
      setDisplayedImage("");
      setFormErrors({
        ...formErrors,
        document: translate(languageData, "notAllowMultipleDots"),
      });
    } else {
      toast(translate(languageData, "somethingwentwrong"), {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    }

    setLoading(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
    <div>
      <ToastContainer />
      <div>
        <Card className="mt-5 pb-5">
          {loading && (
            <div
              className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
              style={{ zIndex: 105000, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <img src={globalLoader} className="mx-auto mt-5" alt="loader1" />
            </div>
          )}
          {loading2 && (
            <div
              className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
              style={{ zIndex: 105000, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <img src={globalLoader} className="mx-auto mt-5" alt="loader1" />
            </div>
          )}
          <Card.Header>
            <h3> {translate(languageData, "AddArticle")}</h3>
          </Card.Header>
          <Card.Body className="border-bottom pb-5">
            <Row className="align-items-center border-bottom pb-4">
              <Col xs={12} md={4}>
                <span>{translate(languageData, "AddArtiImportDoc")} </span>
              </Col>
              <Col xs={12} md={8} className="mt-3 mt-md-0">
                <div>
                  <FileUpload
                    allowedFileExtensions={allowedDocExtensions}
                    getData={handleFileChange}
                    name="document"
                    isData={buttonName}
                    resetIsData={resetIsData}
                  />
                </div>
                {formErrors.document && (
                  <div className="text-danger text-center">
                    {formErrors.document}
                  </div>
                )}
              </Col>
            </Row>
            <div className="my-5">
              <h5 className="fw-bold">
                {translate(languageData, "AddArtiContents")}
              </h5>
            </div>
            <Row className="align-items-center ">
              <Col xs={12} md={4}>
                <span>
                  {translate(languageData, "AddArtiProjectAdvertising")} *
                </span>
              </Col>
              <Col xs={12} md={8} className="mt-3 mt-md-0">
                <div className="form-group d-flex">
                  <select
                    name="project"
                    value={formValues?.project}
                    style={{ height: "45px" }}
                    className=" form-select"
                    id="default-dropdown"
                    data-bs-placeholder="Select Country"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onClick={() => validate(formValues)}
                  >
                    <option
                      label={translate(languageData, "artilstProject")}
                    ></option>
                    {articlesData2.map((item, index) => {
                      return (
                        <option value={JSON.stringify(item)} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        {translate(languageData, "AddProject")}
                      </Tooltip>
                    }
                  >
                    <button
                      className="bg-transparent"
                      onClick={handleShowModal}
                    >
                      <FaPlusCircle />
                    </button>
                  </OverlayTrigger>
                </div>

                <div className="text-danger text-center mt-1">
                  {formErrors.project}
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} md={4}>
                <span>{translate(languageData, "artilstTitle")}*</span>
              </Col>
              <Col xs={12} md={8} className="mt-3 mt-md-0">
                <div
                  className="wrap-input100 validate-input mb-0"
                  data-bs-validate="Password is required"
                >
                  <input
                    className="input100"
                    type="text"
                    name="title"
                    placeholder={translate(languageData, "artilstTitle")}
                    style={{ paddingLeft: "15px" }}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={() => validate(formValues)}
                    value={formValues.title}
                  />
                </div>
                <div className="text-danger text-center mt-1">
                  {formErrors.title}
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} md={4} className="mt-2">
                <span>{translate(languageData, "AddArtiLead")} *</span>
              </Col>
              <Col xs={12} md={8} className="mt-3 mt-md-0">
                <div
                  className="wrap-input100 validate-input mb-0"
                  data-bs-validate="lead is required"
                >
                  <textarea
                    className="input100 py-2"
                    type="text"
                    name="lead"
                    cols={8}
                    rows={10}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={() => validate(formValues)}
                    style={{ paddingLeft: "15px" }}
                    value={formValues.lead}
                  />
                </div>
                <div className="text-danger text-center mt-1">
                  {formErrors.lead}
                </div>
              </Col>
            </Row>
            <Row className="mt-4 pb-8">
              <Col xs={12} md={4} className="mt-2">
                <span>{translate(languageData, "sidebarContent")} *</span>
              </Col>
              <Col xs={12} md={8} className="mt-3 mt-md-0"> 
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    
                    heading: {
                      options: [
                        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                        { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                        { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                        { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                        { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
                        { model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
                        { model: "heading6", view: "h6", title: "Heading 6", class: "ck-heading_heading6" },
                      ],
                    },
                    image: {
                      toolbar: [
                        "imageTextAlternative",
                        "imageStyle:full",
                        "imageStyle:side",
                      ],
                    },
                  }}
                  data={content}
                  onChange={handleEditorChange}
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col xs={12} md={4}>
                <span>{translate(languageData, "AddArtiMainImage")} *</span>
              </Col>
              <Col xs={12} md={1} className="mt-3 mt-md-0 text-center">
                {displayedImage ? (
                  <div>
                    <img src={displayedImage} alt="articleImage" />
                  </div>
                ) : (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {translate(languageData, "uploadImage")}
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsQuestionCircle fontSize={56} />
                    </span>
                  </OverlayTrigger>
                )}
              </Col>
              <Col xs={12} md={3} className="mt-3 mt-md-0">
                <div>
                  <FileUpload
                    allowedFileExtensions={allowedFileExtensions}
                    getData={handleFiles}
                    name="addImage"
                    buttonName={translate(languageData, "uploadImage")}
                    isData={buttonName}
                    resetIsData={resetIsData}
                    isUploadedImg={formValues?.addImage}
                  />
                </div>
                <div className="text-danger text-center mt-1">
                  {formErrors.image}
                </div>
              </Col>
              <Col xs={12} md={1} className="mt-3 mt-md-0">
                <div>{translate(languageData, "orselectviapixabay")}</div>
              </Col>
              <Col xs={12} md={3} className="mt-3 mt-md-0">
                <PixabayImageSearch onSelectImage={handlePixabayImageSelect} />
              </Col>
            </Row>
          </Card.Body>
          <div className="mt-5 ms-auto px-3">
            <Button
              className="btn btn-primary"
              onClick={() => handleAddArticleServices("save")}
              disabled={loading}
            >
              {" "}
              {translate(languageData, "Save")}{" "}
            </Button>
            <Button
              className="btn btn-primary ms-2"
              onClick={() => handleAddArticleServices("saveandexit")}
              disabled={loading2}
            >
              {translate(languageData, "SaveAndExit")}
            </Button>
          </div>
        </Card>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{translate(languageData, "AddProject")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col lg={3} xs={12}>
              {translate(languageData, "NameOfTheProject")} *
            </Col>
            <Col lg={8} xs={12}>
              <div
                className="wrap-input100 validate-input mb-0"
                data-bs-validate="Password is required"
              >
                <input
                  className="input100"
                  type="text"
                  name="projectName"
                  placeholder={translate(languageData, "ProjectName")}
                  style={{ paddingLeft: "15px" }}
                  onChange={(e) => handleChange1(e)}
                  onKeyDown={() => validateProjectModal(formValues1)}
                />
              </div>
              <div className="text-danger text-center mt-1">
                {formErrors.projectName}
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col lg={3} xs={12}>
              {translate(languageData, "WebAddress")} *
            </Col>
            <Col lg={8} xs={12}>
              <div
                className="wrap-input100 validate-input mb-0"
                data-bs-validate="Password is required"
              >
                <input
                  className="input100"
                  type="text"
                  name="webAddress"
                  placeholder={translate(languageData, "WebAddress")}
                  style={{ paddingLeft: "15px" }}
                  onChange={(e) => handleChange1(e)}
                  onKeyDown={() => validateProjectModal(formValues1)}
                />
              </div>
              <div className="text-danger text-center mt-1">
                {formErrors.webAddress}
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col lg={3} xs={12}>
              {translate(languageData, "publicationLanguage")} *
            </Col>
            <Col lg={8} xs={12}>
            <select
                value={formValues1.publicationLang}
                className="form-select"
                style={{fontSize: "15px", height: "45px"}}
                name="publicationLang"
                onChange={(e) => handleChange1(e)}
                onKeyDown={() => validateProjectModal(formValues1)}
                placeholder={translate(languageData, "Language")}
                aria-label="Default select example"
              >
                <option value="" disabled>
                  {translate(languageData, "Language")}
                </option>
                {languagesOpts.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              
              <div className="text-danger text-center mt-1">
                {formErrors.publicationLang}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button variant="secondary" onClick={handleCloseModal}>
              {translate(languageData, "close")}
            </Button>
          </div>
          <div className="">
            <Button
              className="btn btn-primary btn-w-md mx-auto"
              onClick={() => addProjectService()}
              disabled={
                !formValues1.projectName ||
                !formValues1.webAddress ||
                !formValues1.publicationLang ||
                formErrors.publicationLang ||
                formErrors.webAddress ||
                formErrors.projectName
              }
            >
              {loading ? (
                <img src={globalLoader} width={20} />
              ) : (
                translate(languageData, "Save")
              )}{" "}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddArticle;
